import { Component, OnInit } from "@angular/core";
import { outingDate } from "../global_vars";

@Component({
  selector: "app-dickmanns-party",
  templateUrl: "./dickmanns-party.component.html",
  styleUrls: ["./dickmanns-party.component.css"],
})
export class DickmannsPartyComponent implements OnInit {
  partyDate: string = outingDate;

  constructor() {}

  ngOnInit() {}
}
