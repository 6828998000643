<div class="row">
  <div class="col-sm-1"></div>
  <div class="col-sm-10">
    <div class="row row-spaced">
      <h3 class="border-bottom">
        A Special Thanks to all our sponsors who make this event so great...
      </h3>
    </div>
    <div class="row row-spaced">
      <h3 class="border-bottom">(2021 Sponsors)</h3>
    </div>
    <div class="row">
      <img
        src="../../assets/Sponsor_Picture.jpg"
        class="img-fluid center-img"
      />
    </div>
  </div>
</div>

<div class="row">
  <div class="col-sm-1"></div>
  <div class="col-sm-10">
    <div class="row row-spaced">
      <h3 class="border-bottom">
        Silent Auction Items / Company and Friends Donations
      </h3>
    </div>
    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col-sm-4">
        <div *ngFor="let donor of donorList; let i = index">
          <div class="row" *ngIf="i % 2 === 0">
            <h6>{{ donor.lookupValue }}</h6>
          </div>
        </div>
      </div>
      <div class="col-sm-2"></div>
      <div class="col-sm-4">
        <div *ngFor="let donor of donorList; let i = index">
          <div class="row" *ngIf="i % 2 === 1">
            <h6>{{ donor.lookupValue }}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-sm-1"></div>
  <div class="col-sm-10">
    <div class="row row-spaced">
      <h3 class="border-bottom">Hole Sponsors</h3>
    </div>
    <div class="row">
      <div class="col-sm-1"></div>
      <div class="col-sm-4">
        <div *ngFor="let holeSponsor of holeSponsorList; let i = index">
          <div class="row" *ngIf="i % 2 === 0">
            <h6>{{ holeSponsor.lookupValue }}</h6>
          </div>
        </div>
      </div>
      <div class="col-sm-2"></div>
      <div class="col-sm-4">
        <div *ngFor="let holeSponsor of holeSponsorList; let i = index">
          <div class="row" *ngIf="i % 2 === 1">
            <h6>{{ holeSponsor.lookupValue }}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
