import {
  Component,
  OnInit,
  Injectable,
  Renderer2,
  ChangeDetectorRef,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { MatSnackBar } from "@angular/material/snack-bar";

const golferFee: number = 100;
const holeSponsorship: number = 100;

@Component({
  selector: "app-donations",
  templateUrl: "./donations.component.html",
  styleUrls: ["./donations.component.css"],
})
@Injectable({
  providedIn: "root",
})
export class DonationsComponent implements ErrorStateMatcher, OnInit {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
  paymentHandler: any = null;
  paymentToken: string = "";
  paymentDescription: string = "";
  paymentAmount: number = 100.0;
  paymentCurrency: string = "USD";

  //FIELD VARIABLES
  customDonationAmount: number = 100.0;
  donationOption: string = "";
  golferName: string = "";
  golferTwoName: string = "";
  golferThreeName: string = "";
  golferFourName: string = "";
  golferCount: number = 1;
  golferCaptain: string = "";
  includeFees: boolean = true;

  //VISIBILITY / ENABLING VARIABLES
  showGolferPaymentOptions: boolean = false;
  showDonationPaymentOptions: boolean = false;
  showHoleSponsorshipOptions: boolean = false;
  finalizeButtonText: string = "Make Donation";
  finalizeButtonDisabled: boolean = true;
  feeText: string = "Include Fees";
  customDonationNote: string = "";
  isBlurred: boolean = false;
  toastTimeout: number = 30000;

  constructor(
    private router: ActivatedRoute,
    private client: HttpClient,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef
  ) {}
  ngOnInit() {
    this.router.queryParams.subscribe((params) => {
      let donation_option: string | undefined =
        this.router.snapshot.queryParamMap.get("donation_option");
      if (donation_option !== undefined) {
        this.donationOption = donation_option;
        this.setTheControls(donation_option);
      }
    });
    this.invokeStripe();
  }

  makePayment() {
    this.paymentHandler.open({
      name: "Boston Paul Memorial Golf Outing",
      description: this.paymentDescription,
      amount: this.paymentAmount * 100,
    });
  }

  calcAmountFees(amount: number) {
    return amount * 0.03 + 0.5;
  }

  finalizePayment(stripeToken: any) {
    let url = "https://api.bostonpaulgolf.org/create-charge";
    const messageBody = JSON.stringify({
      token: { id: stripeToken.id },
      charge: {
        amount: this.paymentAmount * 100,
        currency: this.paymentCurrency,
      },
      chargeDescription: this.paymentDescription,
    });
    let httpOptions = {
      headers: new HttpHeaders({
        "x-api-key": "qBqGp6RfiC2dNLqqNfBWw9ZCtqOW4QFJ2HZoXHSY",
        "Content-Type": "application/json",
      }),
    };
    this.client.post<any>(url, messageBody, httpOptions).subscribe({
      next: (data) => {
        this.resetForm();
        this.showSuccessSnackBar();
      },
      error: (error) => {
        this.showFailureSnackBar();
        console.error("There was an error processing the payment!", error);
      },
    });
  }

  invokeStripe() {
    if (!window.document.getElementById("stripe-script")) {
      const script = window.document.createElement("script");
      script.id = "stripe-script";
      script.type = "text/javascript";
      script.src = "https://checkout.stripe.com/checkout.js";
      script.onload = (client) => {
        var self = this;
        this.paymentHandler = (<any>window).StripeCheckout.configure({
          key: "pk_live_3r09JKOzlORgnJTPpS01y8lZ",
          image: "/assets/PDR-Logo.png",
          locale: "auto",
          token: function (stripeToken: any) {
            self.finalizePayment(stripeToken);
          },
        });
      };
      window.document.body.appendChild(script);
    }
  }

  showSuccessSnackBar() {
    this.isBlurred = true;
    const snackBarRef = this.snackBar.open(
      "Payment processed successfully",
      "Dismiss",
      {
        duration: this.toastTimeout, // Time in milliseconds, adjust as needed
        horizontalPosition: "center", // You can change the position
        verticalPosition: "top",
        politeness: "polite",
        announcementMessage: "Thank You!",
        panelClass: "custom-snackbar",
      }
    );

    snackBarRef.afterDismissed().subscribe(() => {
      this.isBlurred = false;
      this.cdr.detectChanges();
    });

    setTimeout(() => {
      this.isBlurred = false;
      this.cdr.detectChanges();
    }, this.toastTimeout);
  }

  showFailureSnackBar() {
    this.isBlurred = true;
    const snackBarRef = this.snackBar.open(
      "Failed to process payment",
      "Dismiss",
      {
        duration: this.toastTimeout, // Time in milliseconds, adjust as needed
        horizontalPosition: "center", // You can change the position
        verticalPosition: "top",
        politeness: "assertive",
        announcementMessage: "Sorry Payment Failure",
      }
    );

    snackBarRef.afterDismissed().subscribe(() => {
      this.isBlurred = false;
      this.cdr.detectChanges();
    });

    setTimeout(() => {
      this.isBlurred = false;
      this.cdr.detectChanges();
    }, this.toastTimeout);
  }

  showOptions(e) {
    this.showGolferPaymentOptions = e.value == "golferFee";
    this.showDonationPaymentOptions = e.value == "customDonation";
  }

  setTheControls(e) {
    if (this.donationOption == "customDonation") {
      this.showGolferPaymentOptions = false;
      this.showHoleSponsorshipOptions = false;
      this.showDonationPaymentOptions = true;
      this.paymentAmount = this.customDonationAmount;
      if (this.paymentAmount > 0) {
        this.finalizeButtonDisabled = false;
        this.paymentDescription =
          "Donation of " +
          this.paymentCurrency +
          " made with note: " +
          this.sanitizeString(this.customDonationNote);
        this.finalizeButtonText =
          "Finalize Donation for: $" + this.paymentAmount;
      } else {
        this.finalizeButtonDisabled = true;
        this.finalizeButtonText = "Make Donation";
      }
    } else if (this.donationOption == "golferFee") {
      this.showDonationPaymentOptions = false;
      this.showHoleSponsorshipOptions = false;
      this.showGolferPaymentOptions = true;
      this.feeText =
        "Include Processing Fees: $" +
        this.calcAmountFees(golferFee * this.golferCount).toFixed(2);
      if (this.golferName && this.golferCaptain) {
        this.finalizeButtonDisabled = false;
        if (this.includeFees) {
          this.paymentAmount =
            golferFee * this.golferCount +
            this.calcAmountFees(golferFee * this.golferCount);
        } else {
          this.paymentAmount = golferFee * this.golferCount;
        }
        this.finalizeButtonText =
          "Finalize Payment for: $" + this.paymentAmount;
        this.paymentDescription =
          "Golfers: " +
          this.golferName +
          " " +
          (this.golferCount >= 2 ? this.golferTwoName + " " : "") +
          (this.golferCount >= 4 ? this.golferThreeName + " " : "") +
          (this.golferCount == 4 ? this.golferFourName + " " : "") +
          " Captain: " +
          this.golferCaptain +
          " " +
          " Payment of " +
          this.paymentAmount;
      } else {
        this.finalizeButtonDisabled = true;
        this.finalizeButtonText = "Golfer Payment";
      }
    } else if (this.donationOption == "holeSponsorship") {
      this.showDonationPaymentOptions = false;
      this.showGolferPaymentOptions = false;
      this.showHoleSponsorshipOptions = true;
      this.finalizeButtonDisabled = false;
      this.feeText =
        "Include Processing Fees: $" +
        this.calcAmountFees(holeSponsorship).toFixed(2);
      if (this.includeFees) {
        this.paymentAmount =
          holeSponsorship + this.calcAmountFees(holeSponsorship);
      } else {
        this.paymentAmount = holeSponsorship;
      }
      this.finalizeButtonText =
        "Finalize Donation for: $" + this.paymentAmount.toFixed(2);
      this.paymentDescription =
        "Hole Sponsor note: " + this.sanitizeString(this.customDonationNote);
    } else {
      this.resetForm();
    }
  }

  resetForm() {
    this.showDonationPaymentOptions = false;
    this.showGolferPaymentOptions = false;
    this.showHoleSponsorshipOptions = false;
    this.finalizeButtonDisabled = true;
    this.finalizeButtonText = "Make Donation";
    this.golferName = "";
    this.golferTwoName = "";
    this.golferThreeName = "";
    this.golferFourName = "";
    this.golferCount = 1;
  }

  sanitizeString(str) {
    str = str.replace(/[^a-z0-9áéíóúñü \.,_-]/gim, " ");
    return str.trim();
  }
  removeGolfer(removeGolferNum) {
    if (removeGolferNum == 1 && this.golferCount == 1) {
      this.golferName = "";
    } else {
      if (removeGolferNum == 1 && this.golferCount > 1) {
        this.golferName = this.golferTwoName;
        this.golferTwoName = this.golferThreeName;
        this.golferThreeName = this.golferFourName;
      }
      if (removeGolferNum == 2 && this.golferCount > 2) {
        this.golferTwoName = this.golferThreeName;
        this.golferThreeName = this.golferFourName;
      }
      if (removeGolferNum == 3 && this.golferCount > 3) {
        this.golferThreeName = this.golferFourName;
      }
      this.golferCount -= 1;
    }
    this.setTheControls("event");
  }

  addGolfer() {
    if (this.golferCount == 1) {
      this.golferTwoName = "";
    }
    if (this.golferCount == 2) {
      this.golferThreeName = "";
    }
    if (this.golferCount == 3) {
      this.golferFourName = "";
    }
    this.golferCount += 1;
    this.setTheControls("event");
  }
}
