<div class="row">
  <div class="col-sm-2">
    <app-navbar
      [navbarOptions]="navbarOptions"
      (onNavBarSelectionChange)="setNavSelection($event)"
    >
    </app-navbar>
  </div>
  <div class="col-sm-9 border border-dark border-5 rounded bg-white">
    <div class="row">
      <div class="col-sm-5"></div>
      <div class="col-sm-2">
        <img
          src="../assets/PDR-Logo.png"
          align="middle"
          [class]="mobileDevice() ? 'img-small' : 'img-fluid' + ' title-img'"
        />
      </div>
      <div class="col-sm-5"></div>
    </div>
    <div class="row">
      <div class="col-sm-4"></div>
      <div class="col-sm-4">
        <h2>{{ "Memorial Celebration " + outingYear }}</h2>
      </div>
      <div class="col-sm-4"></div>
    </div>
    <router-outlet></router-outlet>
  </div>
  <div class="row"></div>
  <div class="col-sm-1"></div>
</div>
