import { Component, OnInit } from "@angular/core";
import { outingDate } from "../global_vars";

@Component({
  selector: "app-golf-outing",
  templateUrl: "./golf-outing.component.html",
  styleUrls: ["./golf-outing.component.css"],
})
export class GolfOutingComponent implements OnInit {
  //@Input() GolfPageText: GolfPageText[];
  golfOutingDate: string = outingDate;

  constructor() {}

  ngOnInit() {}
}
