export class DatePageTextItem {
    constructor(
        public dateText: string,
        public dateHeading: string,
        public dateParagraph1: string,
        public dateParagraph2: string,
        public dateParagraph3: string,
        public dateParagraph4: string,
        public dateParagraph5: string,
        public hyperlink: string) { }
}