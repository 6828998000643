export const outingYear: string = "2025";
export const outingMonth: string = "June";
export const outingDay: string = "21";
export const outingDaySuffix: string = "st";
export const outingDate: string =
  outingMonth + " " + outingDay + ", " + outingYear;
export const outingDateShort: string =
  outingMonth + " " + outingDay + outingDaySuffix;
export const annualNum: string = "11th";
export const golfCourse: string = "Devue Golf Course";
// export const golfCourse: string = "Pendleton Hill's Golf Course";
export const golfCourseCity: string = "Covington KY";
// export const golfCourseCity: string = "Butler, KY";
