<div class="row">
  <div class="col-sm-2"></div>
  <div class="col-sm-10">
    <h3>{{ golfOutingYear }} Sponsor Letter</h3>
  </div>
</div>
<div class="row row-spaced">
  <div class="col-sm-2"></div>
  <div class="col-sm-8">
    <a
      href="https://drive.google.com/uc?export=download&id=1D7bjPy9BmrX6-DW27IVWag0EzF4rKp11"
    >
      <img
        src="../../assets/Letter-To-Sponsors.JPG"
        class="img-fluid center-img border"
      />
    </a>
  </div>
</div>
