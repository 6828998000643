<hr />
<h1 style="text-align: center">{{ outingYear + " Hole Assignments" }}</h1>
<div class="row">
  <div class="col-sm-1"></div>
  <div class="col-sm-10">
    <img
      src="../../assets/2021HoleAssignments.jpg"
      class="img-fluid home-img center-block"
    />
  </div>
  <div class="col-sm-1"></div>
</div>
<br /><br />
