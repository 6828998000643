import { Component, OnInit } from "@angular/core";
import { outingYear } from "../global_vars";

@Component({
  selector: "app-sponsors-letter",
  templateUrl: "./sponsors-letter.component.html",
  styleUrls: ["./sponsors-letter.component.css"],
})
export class SponsorsLetterComponent implements OnInit {
  golfOutingYear: string = outingYear;
  constructor() {}

  ngOnInit() {}
}
