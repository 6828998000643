import { Component, OnInit } from "@angular/core";
import { outingYear } from "../global_vars";

@Component({
  selector: "app-hole-assignments",
  templateUrl: "./hole-assignments.component.html",
  styleUrls: ["./hole-assignments.component.css"],
})
export class HoleAssignmentsComponent implements OnInit {
  outingYear: string = outingYear;
  constructor() {}

  ngOnInit() {}
}
