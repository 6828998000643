import { Component } from "@angular/core";
import { Navbar } from "./data-models/navbar.model";
import { outingYear } from "./global_vars";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  //Main Title to the Application
  fullTitle = "Paul Ramsdell Scholarship Fund";
  //Home Page Title Section
  homeTitle = "Paul Ramsdell Scholarship Fund";
  homeSubTitle = "Paul Ramsdell Scholarship Fund";
  outingYear: string = outingYear;

  //Navigation Options
  navbarCurrentSelection: string = "app-home";

  //Navbar list of navigation options
  navbarOptions: Navbar[] = [
    new Navbar("Home", "app-home", "/"),
    new Navbar("Dates", "app-dates", "/dates"),
    // new Navbar("Donations / Golfer Payments", "app-donations", "/donations"),
    new Navbar("Golf Outing", "app-golf-outing", "/golf-outing"),
    // new Navbar("300 Magellan Party", "app-dickmanns-party", "/dickmanns"),
    new Navbar("Sponsors / Donors", "app-sponsors", "/sponsors"),
    // new Navbar("Sponsor Letter", "app-sponsors-letter", "/sponsor-letter"),
    //new Navbar('Golfer Entry', 'app-golfer-payment', '/golfer-payment'),
    //new Navbar('Hole Sponsor', 'app-donations', '/hole-sponsor')
    //,new Navbar('2021 Hole Assignments', 'app-hole-assignments', '/hole-assignments')
  ];

  mobileDevice() {
    if (this.screenSize() < 575) {
      return true;
    } else {
      return false;
    }
  }

  screenSize() {
    //console.log(window.innerWidth);
    return window.innerWidth;
  }

  setNavSelection(navbarSelection: { newSelection: string }): void {
    this.navbarCurrentSelection = navbarSelection.newSelection;
  }

  getNavSelection(): string {
    return this.navbarCurrentSelection;
  }
}
