import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { Navbar } from "../data-models/navbar.model";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnInit {
  navbarSelected: string = "app-home";
  navbarCollapsed: boolean = false;
  @Input() navbarOptions: Navbar[];

  @Output() onNavBarSelectionChange = new EventEmitter<{
    newSelection: string;
  }>();

  ngOnInit() {}

  getNavbarSelected() {
    //console.log(this.navbarSelected);
    return this.navbarSelected;
  }

  onNavbarSelection(navBarSelection: string) {
    this.navbarSelected = navBarSelection;
    this.onNavBarSelectionChange.emit({ newSelection: navBarSelection });
  }

  amIActive(navBarSelection: string) {
    return this.navbarSelected === navBarSelection;
  }

  expandNavOptionsClick() {
    this.navbarCollapsed = !this.navbarCollapsed;
  }
}
