import { Component, OnInit, ElementRef, ViewChild, Input } from "@angular/core";
//import { formDirectiveProvider } from '@angular/forms/src/directives/reactive_directives/form_group_directive';

@Component({
  selector: "app-donation-script",
  templateUrl: "./donation-script.component.html",
  styleUrls: ["./donation-script.component.css"],
})
export class DonationScriptComponent implements OnInit {
  @Input() formId: string;
  scriptSource = "https://checkout.paymentspring.com/js/paymentspring.js";
  scriptType = "text/javascript";
  @ViewChild("script", { static: true }) script: ElementRef;

  constructor() {}

  ngOnInit() {}

  convertToScript() {
    let element = this.script.nativeElement;
    let script = document.createElement("script");
    script.type = this.scriptType;
    script.src = this.scriptSource;
    script.setAttribute("formId", this.formId);
    if (element.innerHTML) {
      script.innerHTML = element.innerHTML;
    }
    let parent = element.parentElement;
    parent.parentElement.replaceChild(script, parent);
  }

  ngAfterViewInit() {
    this.convertToScript();
  }
}
