import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { AppComponent } from "./app.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { GolfOutingComponent } from "./golf-outing/golf-outing.component";
import { HomeComponent } from "./home/home.component";
import { DonationsComponent } from "./donations/donations.component";
import { DonationScriptComponent } from "./donations/donation-script/donation-script.component";
import { GolferPaymentComponent } from "./golfer-payment/golfer-payment.component";
import { GolferPaymentScriptComponent } from "./golfer-payment/golfer-payment-script/golfer-payment-script.component";
import { HoleSponsorComponent } from "./hole-sponsor/hole-sponsor.component";
import { HoleSponsorScriptComponent } from "./hole-sponsor/hole-sponsor-script/hole-sponsor-script.component";
import { DistributionsComponent } from "./distributions/distributions.component";
import { PhotoGalleryComponent } from "./photo-gallery/photo-gallery.component";
import { DatesComponent } from "./dates/dates.component";
import { DickmannsPartyComponent } from "./dickmanns-party/dickmanns-party.component";
import { SponsorsComponent } from "./sponsors/sponsors.component";
import { SponsorsLetterComponent } from "./sponsors-letter/sponsors-letter.component";
import { Routes, RouterModule } from "@angular/router";
import { GolferRegistrationComponent } from "./golfer-registration/golfer-registration.component";
import { HoleAssignmentsComponent } from "./hole-assignments/hole-assignments.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TwoDigitDecimaNumberDirective } from "./two-digit-decima-number.directive";
import { MatSnackBarModule } from "@angular/material/snack-bar";

const appRoutes: Routes = [
  { path: "", component: HomeComponent },
  { path: "home", component: HomeComponent },
  { path: "dates", component: DatesComponent },
  { path: "golf-outing", component: GolfOutingComponent },
  { path: "dickmanns", component: DickmannsPartyComponent },
  { path: "sponsors", component: SponsorsComponent },
  { path: "sponsor-letter", component: SponsorsLetterComponent },
  { path: "donations", component: DonationsComponent },
  { path: "hole-sponsor", component: HoleSponsorComponent },
  { path: "golfer-payment", component: GolferPaymentComponent },
  { path: "golfer-registration", component: GolferRegistrationComponent },
  { path: "gallery", component: PhotoGalleryComponent },
  { path: "hole-assignments", component: HoleAssignmentsComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    GolfOutingComponent,
    DonationsComponent,
    HomeComponent,
    DonationScriptComponent,
    DistributionsComponent,
    PhotoGalleryComponent,
    DatesComponent,
    DickmannsPartyComponent,
    SponsorsComponent,
    SponsorsLetterComponent,
    GolferRegistrationComponent,
    GolferPaymentComponent,
    GolferPaymentScriptComponent,
    HoleSponsorComponent,
    HoleSponsorScriptComponent,
    HoleAssignmentsComponent,
    TwoDigitDecimaNumberDirective,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatSlideToggleModule,
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: "legacy" }),
    BrowserAnimationsModule,
    MatSnackBarModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
