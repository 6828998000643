<div class="row border-top" *ngFor="let item of datePageText">
  <div class="col-sm-3">
    <h2>{{ item.dateText }}</h2>
  </div>
  <div class="col-sm-9">
    <a href="{{ item.hyperlink }}">
      <h2 class="display-4">{{ item.dateHeading }}</h2>
    </a>
    <p class="lead">{{ item.dateParagraph1 }}</p>
    <p class="lead">{{ item.dateParagraph2 }}</p>
    <p class="lead">{{ item.dateParagraph3 }}</p>
    <p class="lead">{{ item.dateParagraph4 }}</p>
    <p class="lead">{{ item.dateParagraph5 }}</p>
  </div>
</div>
