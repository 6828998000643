import { Component, OnInit, Input } from "@angular/core";
import { DatePageTextItem } from "../data-models/date-outing.model";
import {
  annualNum,
  golfCourse,
  golfCourseCity,
  outingDate,
  outingDateShort,
} from "../global_vars";

@Component({
  selector: "app-dates",
  templateUrl: "./dates.component.html",
  styleUrls: ["./dates.component.css"],
})
export class DatesComponent implements OnInit {
  //@Input() datePageText: DatePageTextItem[];

  //Golf Outing Page
  datePageText: DatePageTextItem[] = [
    new DatePageTextItem(
      /* dateText */ outingDateShort,
      /* dateHeading: */ annualNum +
        " Annual Paul Ramsdell Memorial Golf Outing",
      /* dateParagraph1: */ "Saturday, " + outingDate + " 8 AM",
      /* dateParagraph2: */ "Course: " + golfCourse,
      /* dateParagraph3: */ "City: " + golfCourseCity,
      /* dateParagraph4: */ "9 AM Shotgun Start",
      /* dateParagraph5: */ " ",
      /*hyperlink= */ "/golf-outing"
    ),
    new DatePageTextItem(
      /* dateText */ outingDateShort,
      /* dateHeading: */ "300 Magellan Party",
      /* dateParagraph1: */ "Saturday, " + outingDate + " 1 PM",
      /* dateParagraph2: */ "Dickmann's Sports Cafe",
      /* dateParagraph3: */ "Ft. Wright, KY",
      /* dateParagraph4: */ " ",
      /* dateParagraph5: */ " ",
      /*hyperlink= */ "#"
    ),
    // new DatePageTextItem(
    //   /* dateText: */ "TBD",
    //   /* dateHeading: */ annualNum +
    //     " Annual Paul Ramsdell Golf Tournament/Benefit Party",
    //   /* dateParagraph1: */ "TBD",
    //   /* dateParagraph2: */ "TBD",
    //   /* dateParagraph3: */ "Wenham Country Club / Beverly, MA",
    //   /* dateParagraph4: */ " ",
    //   /* dateParagraph5: */ " ",
    //   /*hyperlink= */ "#"
    // ),
  ];

  constructor() {}

  ngOnInit() {}
}
