<div class="container">
  <div class="text-center">
    <h1 class="display-3">{{ "Saturday " + golfOutingDate }}</h1>
  </div>

  <div class="mb-4">
    <h2 class="font-weight-bold">Details:</h2>
    <p class="lead">
      <span class="font-weight-bold">Cost: </span>$125 per golfer
    </p>

    <p class="lead">
      <span class="font-weight-bold">Course: </span>Devue Golf Corse
    </p>

    <p class="lead">
      <span class="font-weight-bold">Included: </span>18 Holes, Breakfast, Lunch and Beverages
    </p>

    <p class="lead">
      <span class="font-weight-bold">What to expect: </span>Friends, good times,
      prizes and a great outing on a beautiful golf course.
    </p>
  </div>
  <div class="mb-4">
    <h2 class="font-weight-bold">Schedule:</h2>
    <p class="lead">
      <span class="font-weight-bold">7:30 AM </span>- Volunteers arrive for
      setup
    </p>

    <p class="lead">
      <span class="font-weight-bold">8:00 AM </span>- Golfer Registration and
      Breakfast
    </p>

    <p class="lead">
      <span class="font-weight-bold">9:00 AM </span>- Shotgun Start
    </p>

    <p class="lead">
      <span class="font-weight-bold">1:00 PM </span>- Lunch and Prizes
    </p>
  </div>
  <div class="mb-4">
    <h2 class="font-weight-bold">Prizes & Giveaways:</h2>
    <p class="lead">
      <span class="font-weight-bold">Closest to the Pin </span>- Holes 7, 10 & 17
      (All Par 3's)
    </p>

    <p class="lead">
      <span class="font-weight-bold">Split the Pot </span>- Sold on day of and
      possible pre-sale
    </p>

    <p class="lead">
      <span class="font-weight-bold">Giveaways </span>- Boston Paul Memorial
      Golf Gear TBD
    </p>

    <p class="lead">
      <span class="font-weight-bold">Silent Aunction Items: </span> TBD
    </p>
  </div>

  <br /><br />

  <div class="text-right">
    <h6 class="lead">*All Prizes and Giveaways are subject to change</h6>
  </div>
</div>
