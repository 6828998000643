import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-golfer-registration',
  templateUrl: './golfer-registration.component.html',
  styleUrls: ['./golfer-registration.component.css']
})
export class GolferRegistrationComponent implements OnInit {
  captain: string;
  captainEmail: string;
  golfer1: string;
  golfer2: string;
  golfer3: string;
  
  constructor() { }

  ngOnInit() {
  }
  
  submitTeam(){

  }
}
