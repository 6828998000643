<div class="row">
  <div class="col-sm-2"></div>
  <div class="col-sm-8">
    <h2 class="border-bottom">Golfer Registration</h2>
    <br>
    <form>
      <div class="form-group">
        <label for="captain">Team Captain</label>
        <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="captain" required class="form-control" id="captain" placeholder="Captain Name">
      </div>
      <div class="form-group">
        <label for="captainEmail">Captain Email address</label>
        <input type="email" [ngModelOptions]="{standalone: true}" [(ngModel)]="captainEmail" required class="form-control" id="captainEmail" aria-describedby="emailHelp" placeholder="Enter email">
        <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
      </div>
      <div class="form-group">
        <label for="golfer1">Golfer 1</label>
        <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="golfer1" required class="form-control" id="golfer1" placeholder="Golfer #1">
      </div>
      <div class="form-group">
        <label for="golfer2">Golfer 2</label>
        <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="golfer2" required class="form-control" id="golfer2" placeholder="Golfer #2">
      </div>
      <div class="form-group">
        <label for="golfer3">Golfer 3</label>
        <input type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="golfer3" required class="form-control" id="golfer3" placeholder="Golfer #3">
      </div>
      <button type="button" class="btn btn-primary" (click)="submitTeam()">Submit</button>
    </form>
  </div>
</div>