<div class="row row-spaced">
  <div class="col-sm-12">
    <h1>Donation Checkout</h1>
    <div class="row">
      <div class="col-sm-12">
        <mat-form-field appearance="fill">
          <mat-label>Select Donation Option</mat-label>
          <mat-select
            (selectionChange)="setTheControls($event)"
            name="donationOption"
            [(ngModel)]="donationOption"
            ngDefaultControl
          >
            <mat-option value="golferFee">Golfer Outing Entry Fee</mat-option>
            <mat-option value="holeSponsorship"
              >Hole Sponsorship Donation</mat-option
            >
            <mat-option value="customDonation"
              >Enter Donation Amount</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="showGolferPaymentOptions" class="row">
      <div class="col-sm-12">
        <section class="golferEntry-section">
          <mat-form-field appearance="fill">
            <mat-label>Team Captain Name</mat-label>
            <input
              matInput
              placeholder="enter team captain name"
              name="golferCaptain"
              [(ngModel)]="golferCaptain"
              ngDefaultControl
              (change)="setTheControls($event)"
            />
            <!-- <mat-hint>Errors appear instantly!</mat-hint>
            <mat-error *ngIf="false">
              Please enter the name of your team captain
            </mat-error>
            <mat-error *ngIf="true">
              Captain Name is <strong>required</strong>
            </mat-error> -->
          </mat-form-field>
        </section>
      </div>
    </div>
    <div *ngIf="showGolferPaymentOptions" class="row">
      <div class="col-sm-12">
        <section class="golferEntry-section">
          <mat-form-field appearance="fill">
            <mat-label>Golfer 1 Name</mat-label>
            <input
              matInput
              placeholder="enter golfer 1 name"
              name="golferName"
              [(ngModel)]="golferName"
              ngDefaultControl
              (change)="setTheControls($event)"
            />
          </mat-form-field>
          <button
            type="button"
            class="btn btn-sm btn-circle btn-secondary pull-right"
            style="margin-left: 5px"
            *ngIf="golferCount > 1"
            (click)="removeGolfer(1)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-trash"
              viewBox="0 0 16 16"
            >
              <path
                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
              ></path>
              <path
                fill-rule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
              ></path>
            </svg>
          </button>
        </section>
      </div>
    </div>
    <div *ngIf="showGolferPaymentOptions && golferCount >= 2" class="row">
      <div class="col-sm-12">
        <section class="golferEntry-section">
          <mat-form-field appearance="fill">
            <mat-label>Golfer 2 Name</mat-label>
            <input
              matInput
              placeholder="enter golfer 2 name"
              name="golferTwoName"
              [(ngModel)]="golferTwoName"
              ngDefaultControl
              (change)="setTheControls($event)"
            />
          </mat-form-field>
          <button
            type="button"
            class="btn btn-sm btn-circle btn-secondary pull-right"
            style="margin-left: 5px"
            *ngIf="golferCount >= 2"
            (click)="removeGolfer(2)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-trash"
              viewBox="0 0 16 16"
            >
              <path
                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
              ></path>
              <path
                fill-rule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
              ></path>
            </svg>
          </button>
        </section>
      </div>
    </div>
    <div *ngIf="showGolferPaymentOptions && golferCount >= 3" class="row">
      <div class="col-sm-12">
        <section class="golferEntry-section">
          <mat-form-field appearance="fill">
            <mat-label>Golfer 3 Name</mat-label>
            <input
              matInput
              placeholder="enter golfer 3 name"
              name="golferThreeName"
              [(ngModel)]="golferThreeName"
              ngDefaultControl
              (change)="setTheControls($event)"
            />
          </mat-form-field>
          <button
            type="button"
            class="btn btn-sm btn-circle btn-secondary pull-right"
            style="margin-left: 5px"
            *ngIf="golferCount >= 3"
            (click)="removeGolfer(3)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-trash"
              viewBox="0 0 16 16"
            >
              <path
                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
              ></path>
              <path
                fill-rule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
              ></path>
            </svg>
          </button>
        </section>
      </div>
    </div>
    <div *ngIf="showGolferPaymentOptions && golferCount == 4" class="row">
      <div class="col-sm-12">
        <section class="golferEntry-section">
          <mat-form-field appearance="fill">
            <mat-label>Golfer 4 Name</mat-label>
            <input
              matInput
              placeholder="enter golfer 4 name"
              name="golferFourName"
              [(ngModel)]="golferFourName"
              ngDefaultControl
              (change)="setTheControls($event)"
            />
          </mat-form-field>
          <button
            type="button"
            class="btn btn-sm btn-circle btn-secondary pull-right"
            style="margin-left: 5px"
            *ngIf="golferCount == 4"
            (click)="removeGolfer(4)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-trash"
              viewBox="0 0 16 16"
            >
              <path
                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
              ></path>
              <path
                fill-rule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
              ></path>
            </svg>
          </button>
        </section>
      </div>
    </div>

    <div *ngIf="showGolferPaymentOptions" class="row">
      <div class="col-sm-12">
        <button
          class="btn btn-small btn-secondary pull-right"
          style="margin-left: 5px"
          *ngIf="golferCount < 4"
          (click)="addGolfer()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-plus-circle"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
            ></path>
            <path
              d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
            ></path>
          </svg>
          Add Golfer
        </button>
      </div>
    </div>

    <div *ngIf="showDonationPaymentOptions" class="row">
      <div class="col-sm-12">
        <section class="golferEntry-section">
          <mat-form-field appearance="fill">
            <mat-label>Donation Amount</mat-label>
            <input
              matInput
              name="customDonationAmount"
              [(ngModel)]="customDonationAmount"
              placeholder="XX.XX"
              ngDefaultControl
              type="textbox"
              appTwoDigitDecimaNumber
              (change)="setTheControls($event)"
            />
            <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
            <mat-error *ngIf="false">
              Please enter the name of your team captain
            </mat-error>
            <mat-error *ngIf="true">
              Donation Amount is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </section>
      </div>
    </div>
    <div
      *ngIf="showDonationPaymentOptions || showHoleSponsorshipOptions"
      class="row"
    >
      <div class="col-sm-12">
        <section class="golferEntry-section">
          <mat-form-field appearance="fill">
            <mat-label>{{
              showHoleSponsorshipOptions ? "Sponsor Sign Text" : "Donation Note"
            }}</mat-label>
            <textarea
              matInput
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              name="customDonationNote"
              [(ngModel)]="customDonationNote"
              ngDefaultControl
              (change)="setTheControls($event)"
              cdkAutosizeMinRows="1"
              cdkAutosizeMaxRows="5"
            ></textarea>
          </mat-form-field>
        </section>
      </div>
    </div>
    <div class="row row-spaced">
      <div
        class="col-sm-12"
        *ngIf="showGolferPaymentOptions || showHoleSponsorshipOptions"
      >
        <mat-slide-toggle
          matInput
          name="includeFees"
          [(ngModel)]="includeFees"
          ngDefaultControl
          (change)="setTheControls($event)"
          >{{ feeText }}</mat-slide-toggle
        >
      </div>
    </div>
    <div class="row row-spaced">
      <div class="col-sm-12">
        <button
          (click)="makePayment()"
          class="btn btn-primary"
          [disabled]="finalizeButtonDisabled"
        >
          {{ finalizeButtonText }}
        </button>
      </div>
    </div>
    <div class="row row-spaced">
      <div class="col-sm-12"></div>
    </div>
  </div>
</div>
<div *ngIf="isBlurred" class="overlay"></div>
