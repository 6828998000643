<div class="container">
  <div class="text-center">
    <h1 class="display-3">300 Magellan Party</h1>
  </div>

  <div class="mb-4">
    <p class="lead">
      <span class="font-weight-bold">Where: </span>300 Magellan aka Dickmann's
      Sports Cafe
    </p>

    <p class="lead">
      <span class="font-weight-bold">When: </span
      >{{ "Saturday " + partyDate + " @ 8:00 PM" }}
    </p>
  </div>
  <div class="mb-4">
    <img src="../../assets/DickmannsLogo.png" class="img-fluid center-img" />

    <img
      src="../../assets/300-Magellan-Sign.JPG"
      class="img-fluid center-img"
    />
  </div>
</div>
