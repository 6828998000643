<div 
    (keydown)="keyPress($event)"
    *ngIf="showModal" 
    [class]="'modal ' + showModal? 'showModal': 'hideModal'">
    <div 
        class="modal-content"
        (keydown)="keyPress($event)">
        <button 
            type="button" 
            class="close" 
            aria-label="Close"
            (click)="closeModalImage()">
            <span aria-hidden="true">&times;</span>
        </button>
        <a 
            class="prev" 
            (keydown)="keyPress($event)"
            (click)="movePhoto(-1)">&#10094;
        </a>
        <a 
            class="next" 
            (keydown)="keyPress($event)"
            (click)="movePhoto( 1)">&#10095;
        </a>
        <img
            (keydown)="keyPress($event)"
            src="{{ currentImageUrl }}{{ currentFileName }}.jpg" 
            class="modal-content"
            height="100%"
            alt="{{ currentFileName }}">
    </div>
    <div 
        (keydown)="keyPress($event)"
        class="modal-background"></div>
</div>
<div class="container page-top">
    <div class="row" *ngIf="!showModal">
        <div class="col-lg-3 col-md-4 col-sm-6 thumb" *ngFor="let photo of photos">
            <a (click)="setCurrentFileName(photo.imageIndex-1)" class="fancybox" rel="ligthbox">
                <img  
                  src="{{ photo.url }}{{ photo.fileName }}_thumbnail.jpg" 
                  class="zoom img-fluid hover-shadow"
                  alt="{{ photo.fileName }}">
            </a>
        </div>
    </div>
</div>