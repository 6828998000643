import { Component, OnInit, Input } from "@angular/core";
import { LookupList } from "../data-models/lookup-list.model";

@Component({
  selector: "app-sponsors",
  templateUrl: "./sponsors.component.html",
  styleUrls: ["./sponsors.component.css"],
})
export class SponsorsComponent implements OnInit {
  //@Input() holeSponsorList: LookupList;
  //@Input() donorList: LookupList;

  donorList: LookupList[] = [
    new LookupList("Tim & Mary Beth Lofton"),
    new LookupList("Sandi & Dylan Ramsdell"),
    new LookupList("Bill Michael"),
    new LookupList("Jennifer and Mike Jackson"),
    new LookupList("Jim Rains"),
    new LookupList("Pam & Gary Schneider"),
    new LookupList("Lester Duett"),
    //new LookupList('Jennifer Sammons Initial Outfitters'),
    //new LookupList('Chelsey Lofton / Lofton Essentials'),
    //new LookupList('Tina Deardorff'),
    new LookupList("Sarah & Jason Cheek"),
    new LookupList("Jim Lucas"),
    //new LookupList('Sammy Smith'),
    //new LookupList('Gerry & Cheryl Buechel'),
    //new LookupList('Mike & Terri Meenach'),
    //new LookupList('Marlene Wamsley & Terrie Jenkins'),
    new LookupList("Adam & Shanel Dean"),
    new LookupList("Greg Goodhew"),
    //new LookupList('Murray Durant'),
    new LookupList("Grant & Caitlyn Schneider"),
    new LookupList("Don Romani"),
    new LookupList("Tanya & Chris Carver"),
    new LookupList("Ryan & Chelsey Stewart"),
    new LookupList("Greg Goodhew"),

    new LookupList("Larry & Dawn Tracy"),
    new LookupList("Barb & Bob Deardorff"),
    new LookupList("Mark & Lisa Flannery"),

    new LookupList("Justin & Deanna True"),
    //new LookupList('Ashley Terry'),
    //new LookupList('Eva Cozine'),
    //new LookupList('Ben Marsh'),
    //new LookupList('Murray Durant'),

    new LookupList("Dickmanns Sports Cafe"),
    new LookupList("Roto-Rooter"),
    new LookupList("Big Bobs Flooring Outlet"),
    new LookupList("Taylor Bros. Excavating"),
    new LookupList("A&P Technology"),
    new LookupList("Michelle Bell / Krusteaz Baking Mixes"),
    new LookupList("AJ Jolly Golf Course"),
    new LookupList("Pendleton Hills Golf Course"),
    new LookupList("Independence Skateway"),
    new LookupList("Florida Tile"),
    new LookupList("Friday Night League"),
  ];

  holeSponsorList: LookupList[] = [
    new LookupList("Ernie & Wendy Slavey"),
    new LookupList("Marlene Wamsley & Terrie Jenkins."),
    new LookupList("Chuck & Mary Smithson"),
    new LookupList("Chris Allen"),
    new LookupList("Jason & Sarah Cheek"),
    new LookupList("Clint & Krista Brandner"),
    new LookupList("Randy & Holly Murphy"),
    new LookupList("Andy & Susan Deardorf"),
    new LookupList("Larry & Dawn Tracy"),
    new LookupList("Adam & Shanel Dean"),
    new LookupList("Terry Smith"),
    new LookupList("Gerry & Cheryl Buechel"),
    new LookupList("Tracy Graham"),
    new LookupList("Jay Miller"),
    new LookupList(
      "2018 Champs (DJ MF Wilder, Mike Adams, Matt Taylor, Joey Mai)"
    ),
    new LookupList(
      "2016 Champs (Sammy Smith, Jeff Jones, Greg Goodhew, Terry Snider)"
    ),
    new LookupList("Bowling Enterprises"),
    new LookupList("Dan Shelton"),
    new LookupList("Eva Cozine"),
    new LookupList("Florida Tile"),
    new LookupList("Friday Night League"),
    new LookupList("Jeff & Jessica Witt"),
    new LookupList("Kelly Eifert"),
    new LookupList("Lester Duett"),
    new LookupList("Mark Flannery"),
    new LookupList("Merrick"),
    new LookupList("Pam Schneider"),
    new LookupList("Scott Wolfe"),
    new LookupList("Sheri Durstock"),
    new LookupList("Steve Lurvey"),
    new LookupList("Steve MacRae"),
    new LookupList("Taylor Brothers"),
    new LookupList("Tim Price "),
    new LookupList("Vicki & Geno Svec"),
    new LookupList("WOW Window Box"),
  ];

  constructor() {}

  ngOnInit() {}
}
