import { Component, OnInit, Input } from '@angular/core';
import { DrivePhotoCaption } from '../data-models/drive-photo-id';

@Component({
  selector: 'app-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.css']
})
export class PhotoGalleryComponent implements OnInit {
  currentImageIndex: number = 0;
  currentFileName: string = '';
  currentImageUrl: string = '';
  showModal: boolean = false;
  s3ImageUrl = 'https://s3.amazonaws.com/ramsdell-memorial-scholarship-images/';
  
  photos: DrivePhotoCaption[] = [
    new DrivePhotoCaption(1, 'IMG_1452', this.s3ImageUrl),
    new DrivePhotoCaption(2, 'IMG_20160625_110054', this.s3ImageUrl),
    new DrivePhotoCaption(3, 'IMG_20160625_123623', this.s3ImageUrl),
    new DrivePhotoCaption(4, 'IMG_20160625_123712', this.s3ImageUrl),
    new DrivePhotoCaption(5, 'IMG_20160625_164531', this.s3ImageUrl),
    new DrivePhotoCaption(6, 'IMG_20160625_165613', this.s3ImageUrl),
    new DrivePhotoCaption(7, 'IMG_20160625_170739', this.s3ImageUrl),
    new DrivePhotoCaption(8, 'IMG_20160625_170739', this.s3ImageUrl),
    new DrivePhotoCaption(9, 'IMG_20160625_170751', this.s3ImageUrl),
    new DrivePhotoCaption(10, 'IMG_20160625_173354', this.s3ImageUrl),
    new DrivePhotoCaption(11, 'IMG_20160625_175157', this.s3ImageUrl),
    new DrivePhotoCaption(12, 'IMG_20160625_184858', this.s3ImageUrl),
    new DrivePhotoCaption(13, 'IMG_20160627_124126_01', this.s3ImageUrl),
    new DrivePhotoCaption(14, 'IMG_20160627_124129_01', this.s3ImageUrl),
    new DrivePhotoCaption(15, 'Party+2', this.s3ImageUrl),
    new DrivePhotoCaption(16, 'Party', this.s3ImageUrl),
    new DrivePhotoCaption(17, 'Signs', this.s3ImageUrl),
    new DrivePhotoCaption(18, '2015-Cooler', this.s3ImageUrl),
    new DrivePhotoCaption(19, '2016-Party-1', this.s3ImageUrl),
    new DrivePhotoCaption(20, 'Food+Line', this.s3ImageUrl), 
    new DrivePhotoCaption(21, 'Food+Tent', this.s3ImageUrl),
    new DrivePhotoCaption(22, 'Gronk+Football', this.s3ImageUrl),
    new DrivePhotoCaption(23, 'IMG_1446', this.s3ImageUrl),
    new DrivePhotoCaption(24, 'IMG_1447', this.s3ImageUrl),
    new DrivePhotoCaption(25, 'IMG_1448', this.s3ImageUrl),
    new DrivePhotoCaption(26, 'IMG_1449', this.s3ImageUrl),
    new DrivePhotoCaption(27, 'IMG_1452', this.s3ImageUrl)
  ];

  constructor() {
   }

  ngOnInit() {
  }

  setCurrentFileName(imageIndex: number){
    this.currentImageIndex = imageIndex;
    this.currentFileName = this.photos[imageIndex].fileName;
    this.currentImageUrl = this.photos[imageIndex].url;
    this.showModal = true;
  }

  closeModalImage(){
    this.showModal = false;
  }

  movePhoto(direction:number){
    let newImageIndex = (this.currentImageIndex + this.photos.length + direction) % this.photos.length;
    this.setCurrentFileName(newImageIndex);
  }

  keyPress(e) {
    // ESCAPE key pressed
    if (e.keyCode == 27) {
      this.closeModalImage();
    }
  }
}